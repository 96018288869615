<template>
  <splitpanes>
    <pane
      class="main-content-pane"
      size="70"
    >
      <div class="main-container">
        <div class="settings-container">
          <div
            v-if="!enableUXRefresh"
            class="settings-header"
          >
            <h1>Settings</h1>
            <settings-actions
              :style="{visibility: $route.name === 'users' ? 'visible' : 'hidden'}"
            />
          </div>
          <PageHeader v-else>
            <template #title>
              <Breadcrumbs
                :breadcrumbs="headerBreadcrumbs"
                @breadcrumb-clicked="onClickBreadcrumb"
              />
            </template>
            <template #actions>
              <settings-actions
                v-if="$route.name === 'users'"
              />
            </template>
          </PageHeader>
          <tabs
            :tabs="settingsPageTabs"
            :variant="enableUXRefresh ? 'underlined' : 'default'"
          >
            <template
              #actions
            >
              <search-bar
                :style="{visibility: $route.name === 'users' ? 'visible' : 'hidden'}"
                :class="{'hidden-search-bar': $route.name !== 'users' }"
                placeholder="Search Users..."
                :value="$route.query.searchBy ?? ''"
                @update-search="updateSearch"
              />
            </template>
          </tabs>
        </div>
      </div>
    </pane>
    <pane
      v-if="isPanelOpen"
      class="pane-details main-content-pane"
      size="30"
      style="minWidth: 450px"
    >
      <aside
        :id="teleportationId"
        :style="{ width: '100%', zIndex: '1', position: 'relative' }"
      />
    </pane>
  </splitpanes>
</template>
<script>
import { useToast } from 'vue-toastification';
import { Splitpanes, Pane } from 'splitpanes';
import { successMessages, errorMessages } from '@/store/helpers/display/toastMessages';
import { mapActions } from 'vuex';
import isSet from '@/store/helpers/isSet';
import debounce from 'lodash.debounce';
import { allowedStates } from '@/store/helpers/storeState';
import SearchBar from '@/components/general/SearchBar.vue';
import Tabs from '@/components/tabs/Tabs.vue';
import SettingsActions from '@/components/settings/SettingsActions.vue';
import 'splitpanes/dist/splitpanes.css';
import { provide, ref } from 'vue';
import FEATURE_FLAGS from '@/store/helpers/featureFlags';
import PageHeader from '@/components/general/PageHeader/PageHeader.vue';
import Breadcrumbs from '@/components/general/Breadcrumbs/Breadcrumbs.vue';

export default {
  components: {
    SettingsActions,
    Splitpanes,
    Pane,
    SearchBar,
    Tabs,
    PageHeader,
    Breadcrumbs,
  },
  setup() {
    const teleportationId = ref('users-sidepanel-container');
    const isPanelOpen = ref(false);
    const closePanel = () => { isPanelOpen.value = false; };
    const openPanel = () => { isPanelOpen.value = true; };

    provide('panelProvider', {
      teleportationId, isPanelOpen, closePanel, openPanel,
    });

    return { teleportationId, isPanelOpen, closePanel };
  },
  data() {
    return {
      toast: useToast(),
      showCreateUserModal: false,
      confirmCreateUserIsLoading: false,
      usersState: allowedStates.IS_BLANK,
      showPermissionsPanel: false,
      canSeeUsersSettings: false,
      enableUXRefresh: FEATURE_FLAGS.ENABLE_UX_REFRESH,
    };
  },
  computed: {
    headerBreadcrumbs() {
      return [
        {
          id: 'settings',
          label: 'Settings',
          isActive: false,
        },
        ...this.$route.name === 'profile' ? [{
          id: 'profile',
          label: 'Profile',
          isActive: true,
        }] : [],
        ...this.$route.name === 'users' ? [{
          id: 'users',
          label: 'Users',
          isActive: true,
        }] : [],
      ];
    },
    canSeeProfileSettings() {
      return this.$store.state.authenticate.cognitoUser
        .signInUserSession.idToken.payload.email.endsWith('@freyda.io') || FEATURE_FLAGS.USER_PROFILE_SETTINGS;
    },
    settingsPageTabs() {
      return [
        ...this.canSeeProfileSettings ? [{
          id: 'PROFILE',
          title: 'Profile',
          routerLinkTo: { name: 'profile' },
          isTabActive: this.$route.name === 'profile',
        }] : [],
        ...this.canSeeUsersSettings ? [{
          id: 'USERS',
          title: 'Users',
          routerLinkTo: { name: 'users' },
          isTabActive: this.$route.name === 'users',
        }] : [],
      ];
    },
  },
  async created() {
    this.canSeeUsersSettings = await this.checkPermission('CAN_LIST_USERS');
    const tabs = this.settingsPageTabs.map((tab) => tab.to);
    // Automatically open the first available tab if tab not specified in url
    if (!tabs.includes(this.$route.name)) {
      this.$router.push({ name: this.settingsPageTabs[0].to });
    }
  },
  methods: {
    ...mapActions({
      init: 'users/init',
      createUser: 'users/createUser',
      checkPermission: 'localisation/checkPermission',
    }),
    onClickBreadcrumb(id) {
      if (id === 'settings') {
        this.$router.push({ name: this.settingsPageTabs[0].to });
      }
    },
    openCreateUserModal() {
      this.showCreateUserModal = true;
    },
    closeCreateUserModal() {
      this.showCreateUserModal = false;
    },
    async onConfirmCreate(name, email) {
      this.confirmCreateUserIsLoading = true;
      await (this.createUser({ name, email }))
        .then(() => {
          this.closeCreateUserModal();
          this.toast.success(successMessages.CREATE_USER);
        })
        .catch((e) => {
          this.$log.error('Failed to create user', e);

          // Display any visible user creation errors, otherwise display
          // a general error message.
          if (isSet(e.response.data.errors)) {
            this.toast.error(e.response.data.errors);
          } else {
            this.toast.error(errorMessages.CREATE_USER);
          }
        })
        .finally(() => {
          this.confirmCreateUserIsLoading = false;
        });
    },
    updateSearch: debounce(async function (query) { // eslint-disable-line func-names
      this.$log.debug('Debounce search triggered:', query);
      this.$router.push({ query: { ...this.$route.query, searchBy: query } });
    }, 700),
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: 0;
  position: relative;
}

.main-content-pane {
  display: -webkit-inline-box;
}
.settings-container {
  display: flex;
  flex-flow: column;
  height: 100%;
  overscroll-behavior: auto;
  width: 100%;
}
.users-filter-bar {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding: 0 32px;
}

.settings-header {
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
}
</style>
